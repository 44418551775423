import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IState {
  setting: {
    data: any;
    employees: any;
    branches: Array<object>;
    
    filter: {
      year: number;
      branch: Object | null;
      department: Object | null;
      type: any;
    };
  };
}

const initialState: IState = {
  setting: {
    data: {},
    employees: {
      data: [],
      currentPage: null,
      total: 0,
      lastPage: null,
      itemPerPage: 10,
      isStopLoading: false,
    },
    branches: [],
    filter: {
      year: new Date().getFullYear(),
      branch: null,
      department: null,
      type: "year"
    },
  },
};

export const settingLabSlice = createSlice({
  name: "settingLab",
  initialState,
  reducers: {

    reset(state) {
      state.setting = {...initialState.setting}
    },

    fetchSetting(state, action) {
      let data = action.payload;
      let settingDates = [];
      for (let i = 1; i <= 12; i++) {
        settingDates.push({
          month: i,
          working_days: 22,
          hours_of_day: 8,
        });
      }
      if(data.MonthlySetting.length <= 0)
      {
        data.MonthlySetting = [...settingDates]
      }


      state.setting.data = { ...data };
    },

    updateSetting(state, action) {
      let { key, val } = action.payload;
      let setting: any = state.setting;
      setting[key] = val;
      state.setting = setting;
    },

    fetchBranches(state, action) {
      let data = action.payload;
      state.setting.branches = [...data];
    },

    fetchEmployees(state, action) {

      const initMonthlySetting = () => {
        let arr = [];
        for (let i = 1; i <= 12; i++) {
          arr.push({
            month: i,
            working_days: "22",
            hours_of_day: "8",
          });
        }
        return arr;
      };

      let employees = state.setting.employees;
      let data = action.payload;
      employees.data = [...data];
      employees.data = employees.data.map((employee: any) => ({
        ...employee,
        hours_of_day:
          employee.EmployeeSetting.length > 0
            ? employee.EmployeeSetting[0].hours_of_day
            : "8",
        working_days:
          employee.EmployeeSetting.length > 0
            ? employee.EmployeeSetting[0].working_days
            : "8",
        monthly_setting:
          employee.EmployeeSetting.length > 0 &&
          employee.EmployeeSetting[0].MonthlySetting.length > 0
            ? employee.EmployeeSetting[0].MonthlySetting
            : initMonthlySetting(),
      }));

      // employees.currentPage = data.pagination.current_page;
      // employees.itemPerPage = data.pagination.per_page;
      // employees.total = data.pagination.total;
      // employees.lastPage = data.pagination.last_page;
      // if (employees.currentPage === data.pagination.last_page) {
      //   employees.isStopLoading = true;
      // }
      state.setting.employees = employees;
    },

    updateEmployees(state, action) {
      let employees = state.setting.employees;
      let data = action.payload;
      employees.data = [...data];
      state.setting.employees = employees;
    },

    
    updateSettingFilter(state, action) {
      let filter : any = { ...state.setting.filter };
      let data = action.payload;

      for (let prop in data) {
        filter[prop] = data[prop];
      }
      state.setting.filter = filter;
    },
  },
  extraReducers: (builder) => {},
});

export default settingLabSlice;
export const settingLabReducer = settingLabSlice.reducer;
export const settingLabSliceAction = settingLabSlice.actions;
