import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { forEach } from "lodash";

interface IState {
  filter: any;
  statisticRankings: any;
  rankEmployees: Array<any>;

}

const initialState: IState = {
  statisticRankings: {
    from: null,
    to: null,
    data: [],
    pagination: {
      currentPage: 1,
      total: 0,
      lastPage: null,
      itemPerPage: 10,
      isStopLoading: false,
      limit: 20,
    },
    isScrollLoading: false,
    isLoadingApi: false,

    totalProcessOfEmployees: [],
    avgProductProcessData: [],
    avgTotalFastestTime: 0,
  },


  filter: {
    type: "month", // month, year, half_year,
    isAllTimeType: false,
    month: null,
    year: null,
    day: null,
    from: null,
    to: null,
    branches: [],
    departments: [],
    users: [],
    roles: [],
    allTimeFlg: false,

    currentClass: null,
    currentCategory: null,
    products: [],

    modals: {
      showRole: false,
      showBranch: false,
      showDepart: false,
      showProduct: false,
    },
  },

  rankEmployees: [],


};

const mapTotalProcessOfEmployee = (data: Array<any>) => {
  return data.map((employee: any) => ({
      employee_id: employee.id,
      name_mei: employee.name_mei,
      name_sei: employee.name_sei,
      evm_user_role: employee.evm_user_role,
      user_type: employee.user_type,
      total_fastest_time: employee.total_fastest_time,
      total_ranking: employee.total_ranking,
  }))
}

const mapRankingData = (data: Array<any>, productProcesses: Array<any>, avgProductProcessData: Array<any>) => {

  let response : any = [];
  productProcesses.forEach((process: any, processIndex: number) => {
    
    let rankEmployees = data.map((employee: any) => {
      return employee.product_process_ranking_data.filter((rank: any) => rank.process_id === process.id)
      .map((item: any, itemIndex: number) => ({
        ...item,
        employee_id: employee.id,
        name_mei: employee.name_mei,
        name_sei: employee.name_sei,
        evm_user_role: employee.evm_user_role,
        user_type: employee.user_type,
        avg_fastest_time_in_period: avgProductProcessData[processIndex]?.avg_fastest_time_in_period,

      }))
    }).flat()

    response.push({
      process_id: process.id,
      process_name: process.name,
      rankEmployees: rankEmployees
    })
  })

  return response;
}

export const statisticRankingSlice = createSlice({
  name: "statisticRanking",
  initialState,
  reducers: {
    resets(state) {
      state.statisticRankings = { ...initialState.statisticRankings };
      state.filter = { ...initialState.filter };
      state.rankEmployees = [];
    },

    resetRankings(state) {
      state.statisticRankings = { ...initialState.statisticRankings };
      state.statisticRankings.isScrollLoading = false;
    },

    resetFilter(state) {
      state.filter = { ...initialState.filter };
    },

    setIsLoadingRankings(state, action) {
      state.statisticRankings.isLoadingApi = action.payload;
    },

    setRankEmployees(state, action)
    {
      state.rankEmployees = [...action.payload]
    },

    fetchRankings(state, action) {
      let statisticRankings = state.statisticRankings;
      const {productProcesses, data} = action.payload;

      let response = mapRankingData(data.users, productProcesses, data.avg_product_process_data);
      statisticRankings.data = [...response];
      statisticRankings.totalProcessOfEmployees = mapTotalProcessOfEmployee(data.users);
      statisticRankings.avgProductProcessData = data.avg_product_process_data;
      statisticRankings.avgTotalFastestTime = data.avg_total_fastest_time;

      // let staffPointPagination = statisticRankings.pagination;

      // // map pagination
      // staffPointPagination.currentPage = pagination.current_page;
      // staffPointPagination.itemPerPage = pagination.per_page;
      // staffPointPagination.total = pagination.total;
      // staffPointPagination.lastPage = pagination.last_page;

      // // check stop load api
      // if (staffPointPagination.currentPage === pagination.last_page) {
      //   staffPointPagination.isStopLoading = true;
      // } else if (staffPointPagination.currentPage < pagination.last_page) {
      //   staffPointPagination.isStopLoading = false;
      // }

      // statisticRankings = {
      //   from: from,
      //   to: to,
      //   data: [],
      //   pagination: staffPointPagination,
      // };

      state.statisticRankings = { ...statisticRankings };
    },

    updateRankings(state, action) {
      const { from, to, data } = action.payload;
      state.statisticRankings = {
        from: from,
        to: to,
        data: data,
      };
    },

    updateFilter(state, action) {
      let filter = { ...state.filter };
      let data = action.payload;

      for (let prop in data) {
        filter[prop] = data[prop];
      }
      state.filter = filter;
    },

    setFilter(state, action) {
      let { key, val } = action.payload;
      let filter = { ...state.filter };
      // handle modals
      if (filter.modals.hasOwnProperty(key)) {
        for (let prop in filter.modals) {
          filter.modals[prop] = false;
        }
        filter.modals[key] = val;
      } else {
        // handle prop
        filter[key] = val;
      }

      state.filter = filter;
    },

    setIsScrollLoading(state, action) {
      state.statisticRankings.isScrollLoading = action.payload;
    },

    resetModal(state) {
      let initFilter = { ...initialState.filter };
      state.filter.modals = { ...initFilter.modals };
    },
  },
});

export default statisticRankingSlice;
export const orderGroupReducer = statisticRankingSlice.reducer;
export const statisticRankingSliceAction = statisticRankingSlice.actions;
